.gray-black {
  color: var(--gray-black);
}
.gray-900 {
  color: var(--gray-900);
}
.gray-800 {
  color: var(--gray-800);
}
.gray-700 {
  color: var(--gray-700);
}
.gray-600 {
  color: var(--gray-600);
}
.gray-500 {
  color: var(--gray-500);
}
.gray-400 {
  color: var(--gray-400);
}
.gray-300 {
  color: var(--gray-300);
}
.gray-200 {
  color: var(--gray-200);
}
.gray-100 {
  color: var(--gray-100);
}
.gray-70 {
  color: var(--gray-70);
}
.gray-50 {
  color: var(--gray-50);
}
.gray-30 {
  color: var(--gray-30);
}
.gray-white {
  color: var(--gray-white);
}

.blue-900 {
  color: var(--blue-900);
}
.blue-800 {
  color: var(--blue-800);
}
.blue-700 {
  color: var(--blue-700);
}
.blue-600 {
  color: var(--blue-600);
}
.blue-500 {
  color: var(--blue-500);
}
.blue-400 {
  color: var(--blue-400);
}
.blue-300 {
  color: var(--blue-300);
}
.blue-200 {
  color: var(--blue-200);
}
.blue-100 {
  color: var(--blue-100);
}
.blue-70 {
  color: var(--blue-70);
}
.blue-50 {
  color: var(--blue-50);
}
.blue-30 {
  color: var(--blue-30);
}

.red-900 {
  color: var(--red-900);
}
.red-800 {
  color: var(--red-800);
}
.red-700 {
  color: var(--red-700);
}
.red-600 {
  color: var(--red-600);
}
.red-500 {
  color: var(--red-500);
}
.red-400 {
  color: var(--red-400);
}
.red-300 {
  color: var(--red-300);
}
.red-200 {
  color: var(--red-200);
}
.red-100 {
  color: var(--red-100);
}
.red-70 {
  color: var(--red-70);
}
.red-50 {
  color: var(--red-50);
}
.red-30 {
  color: var(--red-30);
}

.yellow-900 {
  color: var(--yellow-900);
}
.yellow-700 {
  color: var(--yellow-700);
}
.yellow-500 {
  color: var(--yellow-500);
}
.yellow-300 {
  color: var(--yellow-300);
}
.yellow-100 {
  color: var(--yellow-100);
}

.mint-900 {
  color: var(--mint-900);
}
.mint-800 {
  color: var(--mint-800);
}
.mint-700 {
  color: var(--mint-700);
}
.mint-600 {
  color: var(--mint-600);
}
.mint-500 {
  color: var(--mint-500);
}
.mint-400 {
  color: var(--mint-400);
}
.mint-300 {
  color: var(--mint-300);
}
.mint-200 {
  color: var(--mint-200);
}
.mint-100 {
  color: var(--mint-100);
}
.mint-70 {
  color: var(--mint-70);
}
.mint-50 {
  color: var(--mint-50);
}
.mint-30 {
  color: var(--mint-30);
}

.orange-900 {
  color: var(--orange-900);
}
.orange-800 {
  color: var(--orange-800);
}
.orange-700 {
  color: var(--orange-700);
}
.orange-600 {
  color: var(--orange-600);
}
.orange-500 {
  color: var(--orange-500);
}
.orange-400 {
  color: var(--orange-400);
}
.orange-300 {
  color: var(--orange-300);
}
.orange-200 {
  color: var(--orange-200);
}
.orange-100 {
  color: var(--orange-100);
}
.orange-70 {
  color: var(--orange-70);
}
.orange-50 {
  color: var(--orange-50);
}
.orange-30 {
  color: var(--orange-30);
}
