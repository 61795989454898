:root {
  /* color */
  --gray-black: #000000;
  --gray-900: #1b1e21;
  --gray-800: #2c3136;
  --gray-700: #42494f;
  --gray-600: #5a6169;
  --gray-500: #727a82;
  --gray-400: #8c949c;
  --gray-300: #a7aeb5;
  --gray-200: #c2c8cf;
  --gray-100: #dfe3e8;
  --gray-70: #eef1f5;
  --gray-50: #f4f7fa;
  --gray-30: #fafbfd;
  --gray-white: #ffffff;

  --blue-900: #002999;
  --blue-800: #0035a8;
  --blue-700: #0041cc;
  --blue-600: #0048e3;
  --blue-500: #0050ff;
  --blue-400: #4580ff;
  --blue-300: #6e9eff;
  --blue-200: #8fb8ff;
  --blue-100: #b2cfff;
  --blue-70: #ccdfff;
  --blue-50: #e3edff;
  --blue-30: #edf4ff;

  --red-900: #991f25;
  --red-800: #b22b32;
  --red-700: #cc313e;
  --red-600: #e5404e;
  --red-500: #ff5967;
  --red-400: #ff737e;
  --red-300: #ff99a2;
  --red-200: #ffb2b9;
  --red-100: #ffcfd3;
  --red-70: #ffdde0;
  --red-50: #ffedee;
  --red-30: #fff5f6;

  --yellow-900: #997b0e;
  --yellow-700: #deb215;
  --yellow-500: #ffd633;
  --yellow-300: #ffe880;
  --yellow-100: #fff3b2;

  --mint-900: #137980;
  --mint-800: #188e96;
  --mint-700: #1da4ad;
  --mint-600: #22b0ba;
  --mint-500: #31bec8;
  --mint-400: #4ed0d9;
  --mint-300: #71dbe3;
  --mint-200: #8ee7ed;
  --mint-100: #abf0f5;
  --mint-70: #c8f7fa;
  --mint-50: #e0fdff;
  --mint-30: #edfeff;

  --orange-900: #bd5500;
  --orange-800: #cc5f00;
  --orange-700: #e06e02;
  --orange-600: #f28100;
  --orange-500: #ff9300;
  --orange-400: #ffa933;
  --orange-300: #ffb44f;
  --orange-200: #ffc575;
  --orange-100: #ffdcab;
  --orange-70: #ffe6c4;
  --orange-50: #ffefd9;
  --orange-30: #fff7ed;

  --kakao-yellow: #ffe812;
  --naver-green: #1ec700;
  --apple-black: #000000;

  --membership-intro: #070d24;
  --color-link: #0b4ecb;

  --critical: #ff5967;
  --success: #0d9a38;
  --link: #0b4ecb;
  --warning: #dfa309;

  /* spacing */
  --spacing-size1: 0.1rem;
  --spacing-size2: 0.2rem;
  --spacing-size4: 0.4rem;
  --spacing-size5: 0.5rem;
  --spacing-size6: 0.6rem;
  --spacing-size8: 0.8rem;
  --spacing-size9: 0.9rem;
  --spacing-size10: 1rem;
  --spacing-size12: 1.2rem;
  --spacing-size13: 1.3rem;
  --spacing-size14: 1.4rem;
  --spacing-size16: 1.6rem;
  --spacing-size18: 1.8rem;
  --spacing-size20: 2rem;
  --spacing-size21: 2.1rem;
  --spacing-size22: 2.2rem;
  --spacing-size25: 2.5rem;
  --spacing-size24: 2.4rem;
  --spacing-size28: 2.8rem;
  --spacing-size29: 2.9rem;
  --spacing-size32: 3.2rem;
  --spacing-size34: 3.4rem;
  --spacing-size36: 3.6rem;
  --spacing-size38: 3.8rem;
  --spacing-size40: 4rem;
  --spacing-size44: 4.4rem;
  --spacing-size46: 4.6rem;
  --spacing-size48: 4.8rem;
  --spacing-size54: 5.4rem;
  --spacing-size56: 5.6rem;
  --spacing-size60: 6rem;
  --spacing-size64: 6.4rem;
  --spacing-size70: 7rem;
  --spacing-size80: 8rem;
  --spacing-size104: 10.4rem;
  --spacing-size200: 20rem;
  --spacing-size233: 23.3rem;
  --spacing-size320: 32rem;

  /* icon-size */

  --icon-size-11: 1.1rem;
  --icon-size-12: 1.2rem;
  --icon-size-16: 1.6rem;
  --icon-size-18: 1.8rem;
  --icon-size-20: 2rem;
  --icon-size-24: 2.4rem;
  --icon-size-32: 3.2rem;
  --icon-size-42: 4.2rem;
  --icon-size-48: 4.8rem;
  --icon-size-50: 5rem;
  --icon-size-56: 5.6rem;
  --icon-size-64: 6.4rem;
  --icon-size-176: 17.6rem;

  /* fixed height */
  --header-height: 5.6rem;
  --home-navigation-bar-height: 4.8rem;
}
