.font-heading1-bold {
  font-family: 'Pretendard';
  font-weight: 700;
  font-size: 3rem;
  line-height: 3.6rem;
  letter-spacing: -0.02em;
}

.font-heading1-semibold {
  font-family: 'Pretendard';
  font-weight: 600;
  font-size: 3rem;
  line-height: 3.6rem;
  letter-spacing: -0.02em;
}

.font-heading1-medium {
  font-family: 'Pretendard';
  font-weight: 500;
  font-size: 3rem;
  line-height: 3.6rem;
  letter-spacing: -0.02em;
}

.font-heading1-regular {
  font-family: 'Pretendard';
  font-weight: 400;
  font-size: 3rem;
  line-height: 3.6rem;
  letter-spacing: -0.02em;
}

.font-heading2-bold {
  font-family: 'Pretendard';
  font-weight: 700;
  font-size: 2.6rem;
  line-height: 3.6rem;
  letter-spacing: -0.02em;
}

.font-heading2-semibold {
  font-family: 'Pretendard';
  font-weight: 600;
  font-size: 2.6rem;
  line-height: 3.6rem;
  letter-spacing: -0.02em;
}

.font-heading2-medium {
  font-family: 'Pretendard';
  font-weight: 500;
  font-size: 2.6rem;
  line-height: 3.6rem;
  letter-spacing: -0.02em;
}

.font-heading2-regular {
  font-family: 'Pretendard';
  font-weight: 400;
  font-size: 2.6rem;
  line-height: 3.6rem;
  letter-spacing: -0.02em;
}

.font-heading3-bold {
  font-family: 'Pretendard';
  font-weight: 700;
  font-size: 2.3rem;
  line-height: 3.4rem;
  letter-spacing: -0.02em;
}

.font-heading3-semibold {
  font-family: 'Pretendard';
  font-weight: 600;
  font-size: 2.3rem;
  line-height: 3.4rem;
  letter-spacing: -0.02em;
}

.font-heading3-medium {
  font-family: 'Pretendard';
  font-weight: 500;
  font-size: 2.3rem;
  line-height: 3.4rem;
  letter-spacing: -0.02em;
}

.font-heading3-regular {
  font-family: 'Pretendard';
  font-weight: 400;
  font-size: 2.3rem;
  line-height: 3.4rem;
  letter-spacing: -0.02em;
}

.font-heading4-bold {
  font-family: 'Pretendard';
  font-weight: 700;
  font-size: 2.2rem;
  line-height: 3.1rem;
  letter-spacing: -0.02em;
}

.font-heading4-semibold {
  font-family: 'Pretendard';
  font-weight: 600;
  font-size: 2.2rem;
  line-height: 3.1rem;
  letter-spacing: -0.02em;
}

.font-heading4-medium {
  font-family: 'Pretendard';
  font-weight: 500;
  font-size: 2.2rem;
  line-height: 3.1rem;
  letter-spacing: -0.02em;
}

.font-heading4-regular {
  font-family: 'Pretendard';
  font-weight: 400;
  font-size: 2.2rem;
  line-height: 3.1rem;
  letter-spacing: -0.02em;
}

.font-heading5-bold {
  font-family: 'Pretendard';
  font-weight: 700;
  font-size: 2rem;
  line-height: 2.8rem;
  letter-spacing: -0.02em;
}

.font-heading5-semibold {
  font-family: 'Pretendard';
  font-weight: 600;
  font-size: 2rem;
  line-height: 2.8rem;
  letter-spacing: -0.02em;
}

.font-heading5-medium {
  font-family: 'Pretendard';
  font-weight: 500;
  font-size: 2rem;
  line-height: 2.8rem;
  letter-spacing: -0.02em;
}

.font-heading5-regular {
  font-family: 'Pretendard';
  font-weight: 400;
  font-size: 2rem;
  line-height: 2.8rem;
  letter-spacing: -0.02em;
}

.font-heading6-bold {
  font-family: 'Pretendard';
  font-weight: 700;
  font-size: 1.8rem;
  line-height: 2.5rem;
  letter-spacing: -0.02em;
}

.font-heading6-semibold {
  font-family: 'Pretendard';
  font-weight: 600;
  font-size: 1.8rem;
  line-height: 2.5rem;
  letter-spacing: -0.02em;
}

.font-heading6-medium {
  font-family: 'Pretendard';
  font-weight: 500;
  font-size: 1.8rem;
  line-height: 2.5rem;
  letter-spacing: -0.02em;
}

.font-heading6-regular {
  font-family: 'Pretendard';
  font-weight: 400;
  font-size: 1.8rem;
  line-height: 2.5rem;
  letter-spacing: -0.02em;
}

.font-body1-bold {
  font-family: 'Pretendard';
  font-weight: 700;
  font-size: 1.6rem;
  line-height: 2.2rem;
  letter-spacing: -0.02em;
}

.font-body1-semibold {
  font-family: 'Pretendard';
  font-weight: 600;
  font-size: 1.6rem;
  line-height: 2.2rem;
  letter-spacing: -0.02em;
}

.font-body1-medium {
  font-family: 'Pretendard';
  font-weight: 500;
  font-size: 1.6rem;
  line-height: 2.2rem;
  letter-spacing: -0.02em;
}

.font-body1-regular {
  font-family: 'Pretendard';
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 2.2rem;
  letter-spacing: -0.02em;
}

.font-body2-bold {
  font-family: 'Pretendard';
  font-weight: 700;
  font-size: 1.5rem;
  line-height: 2.1rem;
  letter-spacing: -0.02em;
}

.font-body2-semibold {
  font-family: 'Pretendard';
  font-weight: 600;
  font-size: 1.5rem;
  line-height: 2.1rem;
  letter-spacing: -0.02em;
}

.font-body2-medium {
  font-family: 'Pretendard';
  font-weight: 500;
  font-size: 1.5rem;
  line-height: 2.1rem;
  letter-spacing: -0.02em;
}

.font-body2-regular {
  font-family: 'Pretendard';
  font-weight: 400;
  font-size: 1.5rem;
  line-height: 2.1rem;
  letter-spacing: -0.02em;
}

.font-body3-bold {
  font-family: 'Pretendard';
  font-weight: 700;
  font-size: 1.4rem;
  line-height: 2rem;
  letter-spacing: -0.02em;
}

.font-body3-semibold {
  font-family: 'Pretendard';
  font-weight: 600;
  font-size: 1.4rem;
  line-height: 2rem;
  letter-spacing: -0.02em;
}

.font-body3-medium {
  font-family: 'Pretendard';
  font-weight: 500;
  font-size: 1.4rem;
  line-height: 2rem;
  letter-spacing: -0.02em;
}

.font-body3-regular {
  font-family: 'Pretendard';
  font-weight: 400;
  font-size: 1.4rem;
  line-height: 2rem;
  letter-spacing: -0.02em;
}

.font-body4-bold {
  font-family: 'Pretendard';
  font-weight: 700;
  font-size: 1.3rem;
  line-height: 1.8rem;
  letter-spacing: -0.02em;
}

.font-body4-medium {
  font-family: 'Pretendard';
  font-weight: 500;
  font-size: 1.3rem;
  line-height: 1.8rem;
  letter-spacing: -0.02em;
}

.font-body4-regular {
  font-family: 'Pretendard';
  font-weight: 400;
  font-size: 1.3rem;
  line-height: 1.8rem;
  letter-spacing: -0.02em;
}

.font-body5-bold {
  font-family: 'Pretendard';
  font-weight: 700;
  font-size: 1.2rem;
  line-height: 1.7rem;
  letter-spacing: -0.02em;
}

.font-body5-medium {
  font-family: 'Pretendard';
  font-weight: 500;
  font-size: 1.2rem;
  line-height: 1.7rem;
  letter-spacing: -0.02em;
}

.font-body5-regular {
  font-family: 'Pretendard';
  font-weight: 400;
  font-size: 1.2rem;
  line-height: 1.7rem;
  letter-spacing: -0.02em;
}

.font-caption1-bold {
  font-family: 'Pretendard';
  font-weight: 700;
  font-size: 1.1rem;
  line-height: 1.5rem;
  letter-spacing: -0.02em;
}

.font-caption1-semibold {
  font-family: 'Pretendard';
  font-weight: 600;
  font-size: 1.1rem;
  line-height: 1.5rem;
  letter-spacing: -0.02em;
}

.font-caption1-medium {
  font-family: 'Pretendard';
  font-weight: 500;
  font-size: 1.1rem;
  line-height: 1.5rem;
  letter-spacing: -0.02em;
}

.font-caption1-regular {
  font-family: 'Pretendard';
  font-weight: 400;
  font-size: 1.1rem;
  line-height: 1.5rem;
  letter-spacing: -0.02em;
}

.font-caption2-bold {
  font-family: 'Pretendard';
  font-weight: 700;
  font-size: 1rem;
  line-height: 1.4rem;
  letter-spacing: -0.02em;
}

.font-caption2-semibold {
  font-family: 'Pretendard';
  font-weight: 600;
  font-size: 1rem;
  line-height: 1.4rem;
  letter-spacing: -0.02em;
}

.font-caption2-medium {
  font-family: 'Pretendard';
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.4rem;
  letter-spacing: -0.02em;
}

.font-caption2-regular {
  font-family: 'Pretendard';
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.4rem;
  letter-spacing: -0.02em;
}
